<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiPuzzleOutline }}
      </v-icon>
      <span class="text-break">Extension Settings</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            v-show="extensionData.id"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="extensionData.id"
              label="Extension ID (read-only) *"
              dense
              outlined
              :disabled="true"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="extensionData.label"
              label="Label"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="extensionData.id"
            cols="12"
            md="6"
          >
            <v-select
              v-model="extensionData.status"
              dense
              :disabled="extensionData.status !== 'ENABLED' && extensionData.status !== 'DISABLED'"
              outlined
              label="Status *"
              :items="extensionData.status !== 'ENABLED' && extensionData.status !== 'DISABLED' ? status : status_editable"
            ></v-select>
          </v-col>

          <v-col
            v-if="extensionData.id && extensionData.build"
            cols="12"
            md="6"
          >
            <v-select
              v-model="extensionData.build.status"
              dense
              disabled
              outlined
              label="Build Status (read-only) *"
              :items="buildStatus"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="extensionData.config.code"
              :readonly="!!extensionData.id"
              outlined
              rows="10"
              :label="code_label()"
            ></v-textarea>
            <div>Code displayed here is base64-decoded here. Must be provided base64-encoded via API.</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-checkbox
              v-model="hasRule"
              dense
              outlined
              label="Event Rule"
              title="Whether to attach an event rule."
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="extensionData.config.rule"
            md="6"
            cols="12"
          >
            <v-select
              v-model="extensionData.config.rule.type"
              dense
              outlined
              label="Event Types"
              hide-details="auto"
              :items="event_types"
              item-text="text"
              item-value="value"
              multiple
            ></v-select>
          </v-col>

          <v-col
            v-if="extensionData.config.rule"
            md="6"
            cols="12"
          >
            <v-select
              v-model="extensionData.config.rule.action"
              dense
              outlined
              label="Event Actions"
              hide-details="auto"
              :items="event_actions"
              item-text="text"
              item-value="value"
              multiple
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="extensionData.config.rule"
            md="6"
            cols="12"
          >
            <v-select
              v-model="extensionData.config.rule.result"
              dense
              outlined
              label="Event Results"
              hide-details="auto"
              :items="event_results"
              item-text="text"
              item-value="value"
              multiple
            ></v-select>
          </v-col>

          <v-col
            v-if="extensionData.config.rule"
            md="6"
            cols="12"
          >
            <v-select
              v-model="extensionData.config.rule.reason"
              dense
              outlined
              label="Event Reasons"
              hide-details="auto"
              :items="event_reasons"
              item-text="text"
              item-value="value"
              multiple
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiWebhook, mdiPuzzleOutline,
} from '@mdi/js'

export default {
  /**
   * props
   */
  props: {
    extensionData: {
      type: Object,
      default: () => {},
    },
    mode: { type: String, default: undefined },
    hasRule: { type: Boolean, default: () => false },
  },

  /**
   * data
   */
  data() {
    return {
      requiredRule: [
        value => !!value || 'Required',
      ],
    }
  },

  watch: {
    hasRule(val) {
      if (val) {
        if (!this.extensionData.config.rule) this.extensionData.config.rule = {}
      } else {
        delete this.extensionData.config.rule
      }
    },
  },

  /**
   * methods
   */
  methods: {
    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * code_label
     */
    code_label() {
      return this.mode === 'create' ? 'Code *' : 'Code (read-only)'
    },
  },

  /**
   * setup
   */
  setup() {
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'Locked', value: 'LOCKED' },
    ]

    const status_editable = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]

    const buildStatus = [
      { text: 'Queued', value: 'QUEUED' },
      { text: 'Started', value: 'STARTED' },
      { text: 'Succeeded', value: 'SUCCEEDED' },
      { text: 'Failed', value: 'FAILED' },
    ]

    const event_types = [
      { text: 'All', value: 'ALL' },
      { text: 'API', value: 'API' },
      { text: 'Data', value: 'DATA' },
      { text: 'Database', value: 'DATABASE' },
      { text: 'Authentication', value: 'AUTHENTICATION' },
      { text: 'Authorization', value: 'AUTHORIZATION' },
      { text: 'Communication', value: 'COMMUNICATION' },
    ]

    const event_actions = [
      { text: 'All', value: 'ALL' },

      // ACOUNTS
      { text: 'List Accounts', value: 'list-accounts' },
      { text: 'Get Account', value: 'get-account' },
      { text: 'Create Account', value: 'create-account' },
      { text: 'Update Account', value: 'update-account' },
      { text: 'Delete Account', value: 'delete-account' },
      { text: 'Warn Account (Expiration)', value: 'warn-account' },

      // EXTENSIONS
      { text: 'List Extensions', value: 'list-extensions' },
      { text: 'Get Extension', value: 'get-extension' },
      { text: 'Create Extension', value: 'create-extension' },
      { text: 'Update Extension', value: 'update-extension' },
      { text: 'Delete Extension', value: 'delete-extension' },

      // TENANTS
      { text: 'List Tenants', value: 'list-tenants' },
      { text: 'Get Tenant', value: 'get-tenant' },
      { text: 'Create Tenant', value: 'create-tenant' },
      { text: 'Update Tenant', value: 'update-tenant' },
      { text: 'Delete Tenant', value: 'delete-tenant' },
      { text: 'List Events', value: 'list-events' },
      { text: 'Warn Tenant (Expiration)', value: 'warn-tenant' },

      // CONTROLS
      { text: 'Access Client', value: 'access-client' },
      { text: 'Post Controls', value: 'post-controls' },
      { text: 'List Consents', value: 'list-consents' },
      { text: 'Get Consent', value: 'get-consent' },
      { text: 'Create Consent', value: 'create-consent' },
      { text: 'Post Consent', value: 'post-consent' },
      { text: 'Update Consent', value: 'update-consent' },
      { text: 'Delete Consent', value: 'delete-consent' },
      { text: 'List Controls', value: 'list-controls' },
      { text: 'Get Controls', value: 'get-controls' },
      { text: 'Get Control', value: 'get-control' },
      { text: 'Create Control', value: 'create-control' },
      { text: 'Update Control', value: 'update-control' },
      { text: 'Delete Control', value: 'delete-control' },
      { text: 'List Permissions', value: 'list-permissions' },
      { text: 'Get Permission', value: 'get-permission' },
      { text: 'Create Permission', value: 'create-permission' },
      { text: 'Update Permission', value: 'update-permission' },
      { text: 'Delete Permission', value: 'delete-permission' },
      { text: 'List Rules', value: 'list-rules' },
      { text: 'Get Rule', value: 'get-rule' },
      { text: 'Create Rule', value: 'create-rule' },
      { text: 'Update Rule', value: 'update-rule' },
      { text: 'Delete Rule', value: 'delete-rule' },

      // FACTORS
      { text: 'Post Factors (Login)', value: 'post-login' },
      { text: 'Post Factors (Signup)', value: 'post-signup' },
      { text: 'Get Factors', value: 'get-factors' },
      { text: 'Enroll Factor', value: 'enroll-factor' },
      { text: 'Validate Factor', value: 'validate-factor' },
      { text: 'List Enrollments', value: 'list-enrollments' },
      { text: 'Get Enrollment', value: 'get-enrollment' },
      { text: 'Create Enrollment', value: 'create-enrollment' },
      { text: 'Update Enrollment', value: 'update-enrollment' },
      { text: 'Delete Enrollment', value: 'delete-enrollment' },
      { text: 'List Factors', value: 'list-factors' },
      { text: 'Get Factor', value: 'get-factor' },
      { text: 'Create Factor', value: 'create-factor' },
      { text: 'Update Factor', value: 'update-factor' },
      { text: 'Delete Factor', value: 'delete-factor' },
      { text: 'OAuth 2.0 Callback (GET)', value: 'get-oauth2-callback' },
      { text: 'OAuth 2.0 Callback (POST)', value: 'post-oauth2-callback' },
      { text: 'Capture Input', value: 'capture-input' },
      { text: 'Capture Claims', value: 'capture-claims' },
      { text: 'Capture Tokens (Experimental)', value: 'capture-tokens' },
      { text: 'Send OTP', value: 'send-otp' },

      // TOKENS
      { text: 'Get Authorization (OAuth 2.0)', value: 'get-oauth2-authorize' },
      { text: 'Get Token (OAuth 2.0)', value: 'post-oauth2-token' },
      { text: 'Get User Info (OIDC)', value: 'get-oidc1-user-info' },
      { text: 'Get Configuration (OIDC)', value: 'get-oidc1-config' },
      { text: 'Get Keys (JWKS)', value: 'get-jwks' },
    ]

    const event_results = [
      { text: 'All', value: 'ALL' },
      { text: 'Success', value: 'SUCCESS' },
      { text: 'Failed', value: 'FAILED' },
      { text: 'Pending', value: 'PENDING' },
    ]

    const event_reasons = [
      { text: 'All', value: 'ALL' },
      { text: 'Unauthorized [Failed]', value: 'UNAUTHORIZED' },
      { text: 'Internal Error [Failed]', value: 'INTERNAL_ERROR' },
      { text: 'Racing Condition [Failed]', value: 'RACING_CONDITION' },
      { text: 'Missing Parameter [Failed]', value: 'MISSING_PARAMETER' },
      { text: 'Invalid Parameter [Failed]', value: 'INVALID_PARAMETER' },
      { text: 'Quota Reached [Failed]', value: 'QUOTA_REACHED' },
      { text: 'Missing Input [Failed]', value: 'MISSING_INPUT' },
      { text: 'Invalid Input [Failed]', value: 'INVALID_INPUT' },
      { text: 'Reserved Input [Failed]', value: 'RESERVED_INPUT' },
      { text: 'Incorrect Input [Failed]', value: 'INCORRECT_INPUT' },
      { text: 'Enrollment Not Found [Failed]', value: 'ENROLLMENT_NOT_FOUND' },
      { text: 'Enrollment Mismatch [Failed]', value: 'ENROLLMENT_MISMATCH' },
      { text: 'Enrollment Already Exists [Failed]', value: 'ENROLLMENT_ALREADY_EXISTS' },
      { text: 'Rejected Login (OAuth 2.0) [Failed]', value: 'REJECTED_LOGIN' },
      { text: 'Login (OAuth 2.0) [Pending]', value: 'OAUTH2_PENDING' },
      { text: 'Enrollment [Pending]', value: 'ENROLLMENT_PENDING' },
      { text: 'OTP [Pending]', value: 'OTP_PENDING' },
      { text: 'Delivery [Pending]', value: 'DELIVERY_PENDING' },
    ]

    return {
      status,
      status_editable,
      buildStatus,
      event_types,
      event_actions,
      event_results,
      event_reasons,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiWebhook,
        mdiPuzzleOutline,
      },
    }
  },
}
</script>
